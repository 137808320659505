import { useState } from "react";
import { deleteDescriptionField, updateDescriptionField } from "../../../request/descriptionsFields";
import { getUserToken } from "../../../utils/users";
import { i18nData } from "../../../utils/i18n";
import { updateI18nOverride } from "../../../request/i18nOverride";

export default function({description_field, load}){

    const [content, setContent] = useState(description_field.content);
    const [lang, setLang] = useState(null);

    const submit = async()=>{
        if(!lang){
            const res = await updateDescriptionField(getUserToken(), description_field.id, content);
            if(res.success){
                load();
            }else{
                //To do
            }
        }else{
            const res = await updateI18nOverride(getUserToken(), lang, 'content', content, 'descriptions_fields', description_field.id);
            if(res.success){
                load();
            }else{
                //To do
            }
        }
    }

    const remove = async () => {
        const res = await deleteDescriptionField(getUserToken(), description_field.id);
        if(res.success){
            load();
        }
    }

    return(
        <div className="row">
            <div className="col col-8">
                <textarea value={content} onChange={(e)=>{setContent(e.target.value)}} className="form-input" cols="30" rows="6"></textarea>
            </div>
            <div className="col col-4 mb-3">
                <button onClick={submit} className="btn-add w-100">
                    Modifier
                </button>
                <select className="form-input" value={lang} onChange={(e)=>{setLang(e.target.value)}}>
                    <option>-- Langue --</option>
                    <option value={'fr'}>FR</option>
                    <option value={'nl'}>NL</option>
                    <option value={'en'}>EN</option>
                    <option value={'de'}>DE</option>
                </select>
                <button className="btn-delete w-100 mt-3" onClick={remove}>
                    Supprimer
                </button>
            </div>
            <div className='col col-8 mt-3 p-3'>
                    <ul className='border-1'>
                        {
                            (description_field.i18n.map((i18n)=>{
                                console.log({i18n});
                                return(
                                    <li key={i18n.id}>
                                            <h4>{i18n.language_code}</h4>
                                            <p>{i18nData(description_field, 'content', i18n.language_code)}</p>
                                    </li>  
                                );
                            }))
                        }
                    </ul>
            </div>
            <hr />
        </div>
    );
}
