import Nav from "./Nav";
import '../../style/nav.scss'
import '../../style/layoud.scss'
import { userIsConnected } from "../../utils/users";
import { useEffect, useState } from "react";
import { getItemsByProjectId } from "../../request/projects";
import { useParams } from "react-router-dom";
import { getCategoriesByProjectId } from "../../request/itemsCategories";

export default function ({ containt }) {
    if (!userIsConnected()) {
        window.location.href = '/login';
        return null;
    }

    const { projectId, itemId, categoryId } = useParams();

    const [loading, setLoading] = useState(true);

    const [items, setItems] = useState([]);

    const [categories, setCategories] = useState([]);

    const loadItems = async () => {
        const res = await getItemsByProjectId(projectId);
        if (res.success) {
            setItems(res.data.items);
        }
    }

    const loadCategories = async () => {
        const res = await getCategoriesByProjectId(projectId);
        if (res.success) {
            setCategories(res.data);
        }
    }

    useEffect(() => {
        loadItems();
        loadCategories();
        setLoading(false);
    }, []);

    return (
        <div className="layoud">
            <Nav />
            <div className="main">
                <div className="h-100 menu text-center">
                    {
                        <>
                            {
                                (!loading && projectId) ?
                                    <>
                                        <h2>Catégories</h2>
                                        {
                                            categories.map((category) => {
                                                console.log(category.id);
                                                return (
                                                    <div key={category.id} className={(categoryId === category.id) ? 'text-center bold mt-3 c-p' : 'text-center mt-3 c-p'} onClick={() => { window.location.href = `/project/${projectId}/category/${category.id}` }}>
                                                        {
                                                            category.category_name
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                        <button className="btn-add text-center mt-3" onClick={() => { window.location.href = `/project/${projectId}/category` }}>
                                            Ajouter
                                        </button>
                                        <hr />
                                        <h2 className="text-center mt-3">
                                            Produits
                                        </h2>
                                        {items.map((item) => {
                                            return (
                                                <div key={item.id} className={(itemId === item.id) ? 'text-center bold mt-3 c-p' : 'text-center mt-3 c-p'} onClick={() => { window.location.href = `/project/${projectId}/item/${item.id}` }}>
                                                    {
                                                        item.label
                                                    }
                                                </div>
                                            )
                                        }
                                        )

                                        }
                                        <button className="btn-add text-center mt-3" onClick={() => { window.location.href = `/project/${projectId}` }}>Ajouter</button>
                                    </>
                                    :
                                    ''
                            }
                        </>

                    }
                </div>
                <div className="h-100 containt">
                    {containt}
                </div>
            </div>
        </div>
    )
}