import { useState } from 'react';
import './../../style/form-log.scss';
import { register } from '../../request/users';
import { setUserToken } from '../../utils/users';

export default function(){

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [tel, setTel] = useState('');
    const [loading, setLoading] = useState(false);

    const submit = async (e) => {
        e.preventDefault();

        setLoading(true);

        if(password === passwordConfirm){
            const res = await register(email, password, tel);
            if(res.success){
                setUserToken(res.data);
                window.location.href = '/';
            }
            else{
                //set Msg
                setLoading(false);
            }
        }
    }

    return(
        <div className="form-log position-absolute top-50 start-50 translate-middle">
            <form className='text-center' onSubmit={submit}>
                <h1 className='text-center'>
                    Connexion
                </h1>
                <div className='mt-5'>
                    <a href="/" className='mt-5'>Revenir à notre site</a>
                </div>
                <div className='mt-3'>
                    <a href="/login" className='mt-3'>J'ai déjà un compte</a>
                </div>
                <input required value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder='Adresse email' type="email" className="form-input mt-3" />
                <input required value={password} onChange={(e)=>{setPassword(e.target.value)}} placeholder='Mot de passe' type="password" className="form-input mt-3" />
                <input required value={passwordConfirm} onChange={(e)=>{setPasswordConfirm(e.target.value)}} placeholder='Confirmer le mot de passe' type="password" className="form-input mt-3" />
                <input value={tel} onChange={(e)=>{setTel(e.target.value)}} placeholder='Numéro de téléphonne (facultatif)' type="tel" className="form-input mt-3" />
                <button disabled={loading} type='submit' className='mt-3 btn-custom text-center'>
                    Se connecter
                </button>
            </form>
        </div>    
    )
}