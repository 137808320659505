import requestApi from "./request_api.js";
import { key } from "./config.js";
import { getUserToken } from "../utils/users.js";

export async function updateCommandStatus(command_id, status) {
    const res = await requestApi('/updateCommandStatus', 'post', {
        token: key,
        input: {
            id: command_id,
            status: status,
        }
    });

    /**
     * TO DO!
     */
    return res.data;
}

export async function createCommand(project_id, first_name, last_name) {
    const res = await requestApi('/createCommand', 'post', {
        token: key,
        input: {
            project_id: project_id,
            first_name: first_name,
            last_name: last_name,
        }
    });

    /**
     * TO DO!
     */
    return res.data;
}

export async function createPaiementLink(command_id) {
    const res = await requestApi('/createPaiementLink', 'post', {
        token: key,
        input: {
            command_id: command_id,
        }
    });

    /**
    * TO DO!
    */
    return res.data;
}

export async function addItemToCommand(command_id, item_id) {
    const res = await requestApi('/addItemToCommand', 'post', {
        token: key,
        input: {
            command_id,
            item_id,
        }
    });

    /**
     * TO DO!
     */
    return res.data;
}

export async function getCommandsByProjectId(project_id){

    const res = await requestApi('/getCommandsByProjectId', 'post', {
        jwk_token: getUserToken(),
        input:{
            project_id,
        }
    });

    /**
     * TO DO!
     */
    return res.data;
}