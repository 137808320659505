import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getItemById, updateItem } from '../../request/items';
import Layoud from '../Commun/Layoud';
import { createItem } from '../../request/items';
import { getUserToken } from '../../utils/users';
import { updateI18nOverride } from '../../request/i18nOverride';
import { updateDescription } from '../../request/descriptions';
import { i18nData } from '../../utils/i18n';
import AddDescriptionsField from './descriptionsFields/AddDescriptionsField';
import EditDescriptionFields from './descriptionsFields/EditDescriptionFields';
import AddImage from './images/AddImage';
import ImageContRemove from './images/ImageContRemove';
import '../../style/form-log.scss';
import { addCategoryToItem, getCategoriesByProjectId, removeCategoryToItem } from '../../request/itemsCategories';
import { Allergens } from './allergens/Allergens';

export default function () {
    const { itemId, projectId } = useParams();

    const [item, setItem] = useState({});
    const [name, setName] = useState('');
    const [langDescription, setLangDescription] = useState(null);
    const [categories, setCategories] = useState([]);
    const [category_id, set_category_id] = useState('');
    const [link_cat_id, set_link_cat_id] = useState('');

    const [msg, setMsg] = useState('');

    const loadItem = async () => {
        const res = await getItemById(itemId);
        if (res.success) {
            setItem(res.data);
        }
    }

    const loadCategories = async () => {
        const res = await getCategoriesByProjectId(projectId);

        if (res.success) {
            setCategories(res.data);
        } else {
            setMsg(res.msg);
        }
    }

    const add = async () => {
        const res = await createItem(getUserToken(), name, projectId);
        if (res.success) {
            window.location.href = `/project/${projectId}/item/${res.data.id}`
        } else {
            setMsg(res.msg)
        }
    }

    useEffect(() => {
        loadItem();
        loadCategories();
    }, []);

    if (!itemId) {
        return (
            <Layoud containt={
                <div className='row'>
                    <div className='container'>
                        <div className='row mt-5'>
                            <div className='col col-1'>

                            </div>
                            <div className='col col-6'>
                                <input required value={name} onChange={(e) => { setName(e.target.value) }} type="text" placeholder='Nom du nouveau produit' className='form-input' />
                            </div>
                            <div className='col col-3'>
                                <button className='w-100 btn-add p-2' onClick={add}>
                                    Ajouter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            } />
        )
    }

    return (
        <Layoud containt={
            <div className='row p-3'>
                {
                    (item.id) ?
                        <>
                            <div key={item.id} className='col col-8'>
                                <input placeholder='Nom' type="text" onChange={(e) => { setItem({ ...item, label: e.target.value }) }} className='w-100 form-input p-1' value={item.label} />
                            </div>
                            <div className='col col-8 mt-3'>
                                <input type="number" placeholder='Prix' onChange={(e) => { setItem({ ...item, price: e.target.value }) }} className='w-100 form-input p-1' value={item.price} />
                            </div>
                            <div className='col col-8 mt-3'>
                                <input type="number" value={item.qts} onChange={(e) => { setItem({ ...item, qts: e.target.value }) }} placeholder='Quantité' className='form-input' />
                            </div>
                            <div className='col col-8 mt-5 mb-3'>
                                <select onChange={(e) => { set_category_id(e.target.value) }} value={category_id} className='form-input'>
                                    <option value="">-- Catégories --</option>
                                    {
                                        categories.map((category) => {
                                            return (
                                                <option key={category.id} value={category.id}>{category.category_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col col-4 mt-5 mb-3'>
                                <button onClick={async () => { await addCategoryToItem(getUserToken(), item.id, category_id); loadItem() }} className='btn-add'>Ajouter</button>
                            </div>
                            <div className='col col-8 mt-5 mb-3'>
                                <select onChange={(e) => { set_link_cat_id(e.target.value) }} value={link_cat_id} className='form-input'>
                                    <option value="">-- Catégories --</option>
                                    {
                                        item.categories.map((category) => {
                                            return (
                                                <option key={category.id} value={category.link_id}>{category.category_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col col-4 mt-5 mb-5'>
                                <button onClick={async () => { await removeCategoryToItem(getUserToken(), link_cat_id); loadItem() }} className='btn-remove'>Retirer</button>
                            </div>
                            <div className='col col-12'>
                                <h3>
                                    Catégories
                                </h3>
                                <ul>
                                    {
                                        item.categories.map((category) => {
                                            return (
                                                <li key={category.id}>
                                                    {
                                                        category.category_name
                                                    }
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className='col col-12'>
                                <Allergens loadItem={loadItem} item_id={item.id} allergens={item.allergens} />
                            </div>
                            <div className='col col-12 mt-5'>
                                <AddImage load={loadItem} item_id={item.id} />
                            </div>
                            <div className='col col-12'>
                                <ImageContRemove load={loadItem} img_items={item.images} />
                            </div>
                            <div className='col col-8 mt-3'>
                                <textarea onChange={(e) => { setItem({ ...item, description: { ...item.description, text: e.target.value } }) }} value={item.description.text} className='form-input' placeholder='Contenu de la description' cols="30" rows="10"></textarea>
                            </div>
                            <div className='col col-4 mt-3'>
                                <select className='form-input' onChange={(e) => { setLangDescription(e.target.value) }}>
                                    <option>-- Langue --</option>
                                    <option value="fr">FR</option>
                                    <option value="en">EN</option>
                                    <option value="nl">NL</option>
                                    <option value="de">DE</option>
                                </select>
                                <button className='btn-add w-100 mt-3' onClick={async () => { (langDescription) ? await updateI18nOverride(getUserToken(), langDescription, 'text', item.description.text, 'descriptions', item.description.id) && loadItem() : await updateDescription(getUserToken(), item.description.id, item.description.text) && loadItem() }}>Modifier</button>
                            </div>
                            <div className='col col-8 mt-3 p-3'>
                                <ul className='border-1'>
                                    {
                                        (item.description.i18n.map((description) => {
                                            return (
                                                <li key={description.id}>
                                                    <h4>{description.language_code}</h4>
                                                    <p>{i18nData(item.description, 'text', description.language_code)}</p>
                                                </li>
                                            );
                                        }))
                                    }
                                </ul>
                            </div>
                            <div className='col col-12'>
                                <AddDescriptionsField description_id={item.description.id} load={loadItem} />
                            </div>
                            <div className='col col-12'>
                                {
                                    item.description.description_fields.map((description_field) => {
                                        return (
                                            <EditDescriptionFields key={description_field.id} load={loadItem} description_field={description_field} />
                                        )
                                    })
                                }
                            </div>
                            <div className='col col-4 p-3'>
                                <button className='btn-add' onClick={async () => { await updateItem(getUserToken(), item.id, item.price, item.is_visible, item.qts, item.label, item.description) && loadItem(); }}>Modifier</button>
                            </div>
                        </>
                        :
                        <h1 className='text-center mt-3'>
                            Chargement...
                        </h1>
                }

            </div>
        } />
    )
}