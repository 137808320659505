import { getUserToken } from '../utils/users';
import request_api from './request_api';

export async function createItemCategory(jwk_token, project_id, category_name) {

    const res = await request_api('/createItemCategory', 'post', {
        jwk_token,
        input: {
            project_id,
            category_name,
        },
    });

    return res.data;
}

export async function deleteItemCategory(jwk_token, category_id) {

    const res = await request_api('/deleteItemCategory', 'post', {
        jwk_token,
        input: {
            category_id,
        },
    });

    return res.data;
}

export async function addCategoryToItem(jwk_token, item_id, category_id) {

    const res = await request_api('/addCategoryToItem', 'post', {
        jwk_token,
        input: {
            item_id,
            category_id,
        },
    });

    return res.data;
}

export async function removeCategoryToItem(jwk_token, link_id) {

    const res = await request_api('/removeCategoryToItem', 'post', {
        jwk_token,
        input: {
            id: link_id
        },
    });

    return res.data;
}

export async function updateItemCategory(jwk_token, category_id, category_name) {

    const res = await request_api('/updateItemCategory', 'post', {
        jwk_token,
        input: {
            category_id,
            category_name,
        },
    });

    return res.data;
}

export async function getCategoriesByProjectId(project_id) {
    const res = await request_api('/getCatgoriesByProjectId', 'get', { project_id });

    return res.data;
}

export async function getCategoryById(category_id) {
    const res = await request_api('/getCategoryById', 'post', {
        jwk_token: getUserToken(),
        category_id,
    });

    return res.data;
}