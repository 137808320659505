import requestApi from "./request_api.js";

export async function createItem(token, label, project_id) {
    const res = await requestApi('/createItem', 'post', { jwk_token: token, input: { label: label, project_id: project_id } });

    /**
     * TO DO!
     */
    return res.data;
}

export async function updateItem(token, item_id, price, is_visible, qts, label, description) {
    const res = await requestApi('/updateItem', 'post', {
        jwk_token: token,
        input: {
            id: item_id,
            price: price,
            is_visible: is_visible,
            qts: qts, label: label,
            description: description,
        }
    });

    /**
     * TO DO!
     */
    return res.data;
}

export async function deleteItem(token, item_id) {
    const res = await requestApi('/deleteItem', 'post', {
        jwk_token: token,
        input: {
            item_id: item_id,
        },
    });

    /**
     * TO DO!
     */
    return res.data;
}

export async function getItemById(item_id){
    const res = await requestApi('/getItemById', 'get', {item_id});

    return res.data;
}