import { useEffect, useState } from "react";
import Layoud from "../Commun/Layoud";
import { getProjectsForUser } from "../../request/projects";
import { useLocation } from "react-router-dom";

export default function(){

    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const {pathname} = useLocation();

    const loadProjects = async () => {
        const res = await getProjectsForUser();
        if(res.success){
            setProjects(res.data);
            setLoading(false);
        }else{
            // to do
        }
    }

    useEffect(()=>{
        loadProjects();
    }, []);

    if(loading){
        return <Layoud containt={
            <h1 className="text-center mt-5">
                Chargement...
            </h1>
        }/>
    }

    return(
        <Layoud containt={
            <div className="container">
                <div className="row">
                    {
                        !projects.length ? <h1>Aucun projet asigné</h1>
                        :
                        projects.map((project)=>{
                            return (
                                <div key={project.id} className="p-3 col col-6 col-md-4 col-lg-3 mt-3" onClick={()=>{window.location.href = (pathname.startsWith)('/projects') ? `/project/${project.id}` : `/stats/${project.id}`}}>
                                    <div className="project-card p-3">
                                        {project.name}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>}>  
        </Layoud>
    )
}