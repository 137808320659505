import requestApi from "./request_api.js";

export async function updateI18nOverride(token, language_code, field_name, value, model_type, model_id) {
    const res = await requestApi('/updateI18nOverride', 'post', {
        jwk_token: token,
        input: {
            language_code: language_code,
            field_name: field_name,
            value: value,
            model_id: model_id,
            model_type: model_type
        }
    });

    /**
    *TO DO!
    */
    return res.data;
}