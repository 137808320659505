import { getUserToken } from "../utils/users.js";
import requestApi from "./request_api.js";
import '../style/dashboard.scss';

export async function createProject(admin_id, user_id, name) {
    const res = await requestApi('/createProject', 'post', { admin_id: admin_id, input: { user_id: user_id, name: name } });

    /**
     * TO DO!
     */
    return res.data;
}

export async function updateProject(admin_id, project_id, name) {
    const res = await requestApi('/updateProject', 'post', { admin_id: admin_id, input: { id: project_id, name: name } });

    /**
    * TO DO!
    */
    return res.data;
}

export async function getItemsByProjectId(project_id) {
    const res = await requestApi('/getProjectInfos', 'get', { project_id: project_id });

    /**
    * TO DO!
    */
    return res.data;
}

export async function getProjectsForUser(){
    const res = await requestApi('/getProjectsForUser', 'post',{
        jwk_token: getUserToken(),
    });

    return res.data;
}