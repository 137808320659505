import { useState } from "react";
import { updateAllergens } from "../../../request/allergens";
import { getUserToken } from '../../../utils/users';
import React from "react";

export function Allergens({ item_id, allergens, loadItem }) {

    const [updatedAllergens, setUpdatedAllergens] = useState({});

    const handleCheck = (e) => {
        const { name, checked } = e.target;
        setUpdatedAllergens(prevState => ({
            ...prevState,
            [name]: checked ? 1 : 0
        }));
    };

    const update = async () => {
        const res = await updateAllergens(getUserToken(), item_id, updatedAllergens);
        if (res.success) {
            loadItem();
        }
    }

    return (
        <div className="row">
            {
                Object.entries(allergens).filter(([key, value]) => {
                    return isNaN(key);
                }).map((allergen, index) => {
                    return (
                        <React.Fragment key={index}>
                            {
                                (index > 1) ?
                                    <>
                                        <div className="col col-1">
                                            <input 
                                                onChange={handleCheck} 
                                                name={allergen[0]} 
                                                type='checkbox' 
                                                checked={updatedAllergens[allergen[0]] !== undefined ? updatedAllergens[allergen[0]] : allergen[1]} 
                                            />
                                        </div>
                                        <div className="col col-11">
                                            {allergen[0]}
                                        </div>
                                    </>
                                    :
                                    null
                            }
                        </React.Fragment>
                    )
                })
            }

            <div className="col col-4 mt-3">
                <button onClick={update} className="btn btn-add">Mettre à jour</button>
            </div>
        </div>
    )
}
