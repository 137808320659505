import request_api from './request_api';

export async function updateAllergens(token, item_id, allergens) {

    const res = await request_api('/updateAllergens', 'post', {
        jwk_token: token,
        input: {
            item_id,
            allergens,
        },
    });

    return res.data;
}