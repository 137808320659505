import requestApi from './request_api';

export async function addImageItem(token, file, item_id){
    const res = await requestApi('/addImageItem', 'post',{
        jwk_token: token,
        img: file,
        input:{
            item_id,
        },
    });

    return res.data;
}

export async function deleteImageItem(token, id){
    const res = await requestApi('/deleteImageItem', 'post', {
        jwk_token: token,
        input:{
            id,
        },
    });

    return res.data;
}