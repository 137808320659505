import { useEffect, useState } from "react"
import Cookies from "cookies-js";
import {useLocation} from 'react-router-dom';
import { getUserEmail, removeCookieUser, setFullName, userIsConnected } from "../../utils/users"

export default function () {

    const [email, setEmail] = useState('');

    const {pathname} = useLocation();

    const loadEmail = async () =>{
        if(userIsConnected() && (!Cookies.get('email'))){
            await setFullName();
        }
        setEmail(getUserEmail());
    }

    useEffect(()=>{
        loadEmail();
    },[]);

    return(
        <div className="w-100 p-5 nav">
            <div>
                <h1>
                    {email}
                </h1>
            </div>
            <div className="link text-center">
                <a className={(pathname.startsWith('/project')) ? "nav-link underline" : "nav-link"} href="/projects">Mes projets</a>
            </div>
            <div className="link text-center">
                <a className={(pathname.startsWith('/stats')) ? "nav-link underline" : "nav-link"} href="/stats">Mes states</a>
            </div>
            <div className="link text-center">
                <a className="nav-link" onClick={()=>{removeCookieUser()}}>Déconnexion</a>
            </div>
        </div>
    )
}