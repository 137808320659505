export const i18nData = (model, fieldName, language_code) => {

    if (!language_code) return model[fieldName];
    if (!model.i18n) return model[fieldName];

    const localizedFields = model.i18n.filter((f) => f.language_code === language_code);

    if (!!localizedFields) {

        const localizedField = localizedFields.filter((f) => f.field_name === fieldName)[0];

        if (!!localizedField) {
            return localizedField.value;
        }
    }

    return model[fieldName];
};