import { useParams } from "react-router-dom";
import Layoud from "../Commun/Layoud";
import { useEffect, useState } from "react";
import { getCommandsByProjectId, updateCommandStatus } from "../../request/commands";

export default function (){

    const {projectId} = useParams();

    const [commands, setCommands] = useState([]);
    const [loading, setLoading] = useState(true);
    const [disabled, setDisabled] = useState(true);
    
    const loadCommands = async () => {
        setDisabled(true)
        setLoading(true);
        const res = await getCommandsByProjectId(projectId);
        if(res.success){
            setCommands(res.data);
        }
        setDisabled(false);
        setLoading(false);
    }

    useEffect(()=>{
        loadCommands();
    },[]);

    if(loading){
        return(
            <Layoud containt={
            <div>
                <h1 className="mt-5 text-center">
                    Chargement
                </h1>
            </div>
        }>

            </Layoud>
        )
    }

    return (
        <Layoud containt={
            <div className="container">
                <button disabled={disabled} onClick={loadCommands} className="mt-5 btn btn-success">
                    Rafraichir
                </button>
                <div>
                    {
                        commands.map((command, index)=>{
                            return(
                                <div className={index % 2 ? "row bg-l p-3" : "row bg-d p-3"}>
                                    <div className="col col-3">
                                        {`${command.first_name} ${command.last_name}`}
                                    </div>
                                    <div className="col col-6">
                                        <div className="container">
                                            <div className="row">
                                            <div className="col col-8">
                                                Produit
                                            </div>
                                            <div className="col col-4">
                                                Quantité
                                            </div>
                                        </div>
                                            {
                                                command.items.map((item)=>{
                                                    return (
                                                        <div className="row">
                                                            <div className="col col-8">
                                                                {item.label}
                                                            </div>
                                                            <div className="col col-4">
                                                                {item.qts}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="col col-3">
                                        <div className="container">
                                            {command.create_at}
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col col-4">
                                            status: {command.status}
                                        </div>
                                        <div className="col col-4">

                                        </div>
                                        <div className="col col-4">
                                            <button onClick={async()=>{await updateCommandStatus(command.id, 'finish');setDisabled(true);loadCommands()}} className="btn btn-danger w-100">
                                                Ne plus afficher
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        }>

        </Layoud>
    )
}