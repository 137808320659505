import { useState } from "react";
import { addImageItem } from "../../../request/imagesItems"
import { getUserToken } from "../../../utils/users"

export default function({item_id, load}){

    const [file, setFile] = useState(null);

    const sub = async () =>{
        const res = await addImageItem(getUserToken(), file, item_id);
        if(res.success){
            load();
        }
    }

    return(
        <div className="row mt-5 mb-5">
            <div className="col col-4">
                <input type="file" className="form-input" onChange={(e)=>{setFile(e.target.files[0])}} />
            </div>
            <div className="col col-4">
                <button onClick={()=>{sub()}} className="btn-add w-100 p-2">
                    Ajouter
                </button>
            </div>
        </div>
    )
}