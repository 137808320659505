import { useState } from "react";
import { createDescriptionField } from "../../../request/descriptionsFields";
import { getUserToken } from "../../../utils/users";

export default function({description_id, load}){

    const [content, setContent] = useState('');
    const [type, setType] = useState('');

    const submit = async()=>{
        const res = await createDescriptionField(getUserToken(), type,content, description_id);
        if(!res.success){
            //To do
        }
        load();
    }

    return(
        <div className="row">
            <div className="col col-8 p-2">
                <select className="form-input" value={type} onChange={(e)=>{setType(e.target.value)}}>
                    <option value="title">Title</option>
                    <option value="paragraphe">Paragraphe</option>
                </select>
            </div>
            <div className="col col-4 p-3">
                <button onClick={()=>{submit()}} className="btn-add w-100">
                    Ajouter
                </button>
            </div>
            <div className="col col-8 p-1 mb-3">
                <textarea value={content} onChange={(e)=>{setContent(e.target.value)}} className="form-input" cols="30" rows="6"></textarea>
            </div>
            <hr />
        </div>
    );
}