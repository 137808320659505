import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/login/Login';
import Register from './components/register/Register';
import Items from './components/items/Items';
import Category from './components/categories/Category';
import Stats from './components/stats/Stats';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' Component={Dashboard} />
        <Route path='/projects' Component={Dashboard} />
        <Route path='/stats' Component={Dashboard} />
        <Route path='/login' Component={Login} />
        <Route path='/register' Component={Register} />
        <Route path='/project/:projectId' Component={Items} />
        <Route path='/project/:projectId/item/:itemId' Component={Items} />
        <Route path='/project/:projectId/category' Component={Category} />
        <Route path='/project/:projectId/category/:categoryId' Component={Category} />
        <Route path='/stats/:projectId' Component={Stats}/>
      </Routes>
    </Router>
  );
}

export default App;
