import Cookies from 'cookies-js';
import { getUserByToken } from '../request/users';

export function getUserToken(){
    return Cookies.get('token');
}

export function getUserEmail(){
    return Cookies.get('email');
}

export async function setFullName(){
    const res = await getUserByToken(getUserToken());
    if(res.success){
        Cookies.set('email', res.data.email);
    }else{
        removeCookieUser();
    }
}

export function setUserToken(token){
    Cookies.set('token', token);
}

export function userIsConnected(){
    return !!getUserToken();
}

export function removeCookieUser(){
    Cookies.set('token', undefined);
    Cookies.set('email', undefined);
    window.location.href = '/login';
}