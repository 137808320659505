import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layoud from '../Commun/Layoud';
import { i18nData } from '../../utils/i18n';
import '../../style/form-log.scss';
import { createItemCategory, deleteItemCategory, updateItemCategory } from '../../request/itemsCategories';
import { getUserToken } from '../../utils/users';
import { getCategoryById } from '../../request/itemsCategories';
import { updateI18nOverride } from '../../request/i18nOverride';

export default function () {
    const { categoryId, projectId } = useParams();

    const [msg, setMsg] = useState('');
    const [category, setCategory] = useState(null);
    const [name, setName] = useState('');
    const [trad, setTrad] = useState('');
    const [lang, setLang] = useState('');

    useEffect(() => {
        loadCategory();
    }, []);

    const loadCategory = async () => {
        const res = await getCategoryById(categoryId);
        if (res.success) {
            setCategory(res.data);
        } else {
            setMsg(res.msg);
        }
    }

    const add = async () => {
        const res = await createItemCategory(getUserToken(), projectId, name);

        if (res.data.success) {
            window.location.href = `/project/${projectId}/category/${res.data.id}`;
        } else {
            setMsg(res.msg);
        }
    }

    if (!categoryId) {
        return (
            <Layoud containt={
                <div className='row'>
                    <div className='container'>
                        <div className='row mt-5'>
                            <div className='col col-1'>

                            </div>
                            <div className='col col-6'>
                                <input required value={name} onChange={(e) => { setName(e.target.value) }} type="text" placeholder='Nom de la categorie' className='form-input' />
                            </div>
                            <div className='col col-3'>
                                <button className='w-100 btn-add p-2' onClick={add}>
                                    Ajouter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            } />
        )
    }

    return (
        <Layoud containt={
            category ?
                <div className='row p-3'>
                    <div className='col col-8'>
                        <input type="text" onChange={(e) => { setCategory({ ...category, category_name: e.target.value }) }} className='form-input w-100' value={category.category_name} />
                    </div>
                    <div className='col col-4'>
                        <button onClick={async () => { await updateItemCategory(getUserToken(), category.id, category.category_name) }} className='btn-add p-2'>Modifier</button>
                    </div>
                    <div className='col col-8 mt-3'>
                        <input value={trad} type="text" className='form-input w-100' onChange={(e) => { setTrad(e.target.value) }} />
                    </div>
                    <div className='col col-4 mt-3'>
                        <select className='form-input w-100' value={lang} onChange={(e) => { setLang(e.target.value) }}>
                            <option value="">-- Langue --</option>
                            <option value="fr">FR</option>
                            <option value="nl">NL</option>
                            <option value="en">EN</option>
                            <option value="de">DE</option>
                        </select>
                        <button onClick={async () => { await updateI18nOverride(getUserToken(), lang, 'category_name', trad, 'item_category', category.id); loadCategory() }} className='btn-add p-2 mt-1'>Modifier traduction</button>
                    </div>
                    {
                        category.i18n.map((trad) => {
                            return (
                                <div className='col col-8'>
                                    <h3>
                                        {trad.language_code}
                                    </h3>
                                    <p>
                                        {i18nData(category, 'category_name', trad.language_code)}
                                    </p>
                                    <hr />
                                </div>
                            )
                        })
                    }
                    <div className='col col-6 mt-5'>
                        <button className='btn-remove p-2' onClick={async () => { await deleteItemCategory(getUserToken(), category.id); window.location.href = `/project/${projectId}/category` }}>
                            Supprimer
                        </button>
                    </div>
                </div>
                :
                ''
        } />
    )
}