import { deleteImageItem } from "../../../request/imagesItems"
import { getUserToken } from "../../../utils/users"

export default function({load, img_items}){

    const remove = async (item_id) => {
        const res = await deleteImageItem(getUserToken(), item_id);
        if(res.success){
            load();
        }else{
            //To do
        }
    }

    return(
        <div className="row">
            {
                img_items.map((img_item)=>{
                    return(
                        <div className="col col-3" key={img_item.id}>
                            <img src={img_item.uri} height={'auto'} width={'100%'} alt="" />
                            <button className="btn-remove mt-1 w-100" onClick={()=>{remove(img_item.id)}}>Supprimer</button>
                        </div>
                    )
                })
            }
        </div>
    )
}