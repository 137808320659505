import requestApi from "./request_api.js";

export async function updateDescription(token, description_id, text) {
    const res = await requestApi('/updateDescription', 'post', {
        jwk_token: token,
        input: {
            id: description_id,
            text: text,
        }
    });

    /**
     * TO DO!
     */
    return res.data;
}
