import requestApi from "./request_api.js";

export async function login(email, password) {
    const res = await requestApi('/login', 'post', { input: { email: email, password: password } });
    
    /**
    * 
    * TO DO!
    * 
    */
    return res.data;
}

export async function register(email, password, tel) {
    const res = await requestApi('/register', 'post',  {
        input: {
            email: email,
            password: password,
            tel: tel,
            birthday: '1999/02/24',
        }
    }
);

/**
* 
* TO DO!
* 
*/
return res.data;
}

export async function getUserByToken(token) {
    const res = await requestApi('/getUserByToken', 'get', { jwk_token: token });
    
    /**
    * 
    * TO DO!
    * 
    */
    return res.data;
}

export async function updateKeyForUser(admin_id, user_id, type, api_key) {
    const res = await requestApi('/updateKeyForUser', 'post', {
        admin_id,
        input: {
            user_id,
            type,
            key: api_key,
        }
    });
    
    /**
    * 
    * TO DO!
    * 
    */
    return res.data;
}