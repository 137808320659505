import requestApi from "./request_api.js";

export async function createDescriptionField(token, type, content, description_id) {
    const res = await requestApi('/createDescriptionField', 'post',
        { jwk_token: token, input: { type: type, content: content, description_id: description_id } }
    );

    return res.data;
}

export async function updateDescriptionField(token, description_id, content){
    const res = await requestApi('/updateDescriptionField', 'post', {
        jwk_token: token,
        input:{
            description_id,
            content,
        },
    });

    return res.data;
}

export async function deleteDescriptionField(token, description_field_id){
    const res = await requestApi('/deleteDescriptionField', 'post', {
        jwk_token: token,
        input:{
            description_field_id,
        }
    });

    return res.data;
}